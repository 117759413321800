export const Header = {
    init: function () {
        this.$desktopLanguageSelector = $('div#desktop-header div#language_selector');
        this.$mobileLanguageSelector = $('div#mobile-header div#language_selector');

        this.initEvents();
        this.initLanguageSelector();
    },

    initEvents: function () {
        //Allow hover on language selector
        this.$desktopLanguageSelector.on('mouseenter mouseleave', function (e) {
            let show = e.type === 'mouseenter';
            $(this).find('a.expand-more').attr('aria-expanded', show)
                .siblings('div.dropdown-menu').toggleClass('show', show)
                .parent().toggleClass('show', show);
        });

        this.$mobileLanguageSelector.on('click', function () {
            let show = !$(this).find('div.language-selector').hasClass('show');

            $(this).find('a.expand-more').attr('aria-expanded', show)
                .siblings('div.dropdown-menu').toggleClass('show', show)
                .parent().toggleClass('show', show);

            console.log(show);
        });
    },

    initLanguageSelector: function () {
        this.$desktopLanguageSelector.add(this.$mobileLanguageSelector).find('a.expand-more').each(function () {
            let text = $(this).text();
            $(this).html(text.trim().substring(0, 2));
        });
    }
};