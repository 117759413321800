import {Home} from './pages/Home';
import {Category} from './pages/Category';
import {Product} from './pages/Product';
import {Header} from './components/Header';
import {Global} from './Global';

$(document).ready(function () {
    switch ($('body').attr('id')) {
        case 'index':
            Home.init();
            break;
        case 'category':
            Category.init();
            break;
        case 'product':
            Product.init();
            break;
    }
    Header.init();
    Global.init();
});
