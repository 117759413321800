import {Helpers} from './Helpers';

export const Global = {
    init: function () {
        this.initEvents();
        this.dateMask();
        this.contactTooltip();
        this.moreText();

        Helpers.initSwiper('.swiper-reassurance-bottom');
    },

    initEvents: function () {
        setTimeout(function(){
            $('#header').css({ opacity: 1 });
            }, 250);
        $(window).on('scroll', function () {
            $('body').css('--scroll-y', `${window.scrollY}px`);
        });

        $('div.col-mobile-btn-menu')
            .on('show.bs.dropdown', function () {
                Helpers.overflowHidden();
            })
            .on('hide.bs.dropdown', function () {
                Helpers.overflowInitial();
            });
    },
    dateMask: function () {
        if ($("input[name='birthday']").length) {

            var input = document.querySelector('input[name="birthday"]');

            var dateInputMask = function dateInputMask(elm) {
                elm.addEventListener('keypress', function(e) {
                    if(e.keyCode < 47 || e.keyCode > 57) {
                        e.preventDefault();
                    }

                    var len = elm.value.length;

                    if(len !== 1 || len !== 3) {
                        if(e.keyCode == 47) {
                            e.preventDefault();
                        }
                    }

                    if(len === 2) {
                        elm.value += '/';
                    }

                    if(len === 5) {
                        elm.value += '/';
                    }
                });
            };

            dateInputMask(input);

        }
    },
    contactTooltip: function () {

        $(".contact-form .gdpr_module input").change(function() {
            let sptBtn =  $('.form-footer input[type="submit"]');
            let title = sptBtn.data("title");
            if(this.checked) {
                sptBtn.attr('title', "");

            }else {
                sptBtn.attr('title', title);
            }
        })

    },
    moreText: function () {

        if ($('#category #category-description').length) {
            var title =  $('.to-open').data("title");
            var titleClose =  $('.to-open').data("title2");
            var hContent = $('#category-description > div').height();
            if ( hContent <  225) {
                $("#category-description").addClass('no-limit');
                $(".to-open").addClass('no-limit');
            }
            $('.to-open').on('click', function () {
                var wContent = $('#category-description');
                if (wContent.hasClass('open')) {
                    wContent.removeClass('open').animate({
                        height: '180'
                    }, 500, function () {
                        $('.to-open').text(title)
                    });
                } else {
                    wContent.addClass('open').animate({
                        height: hContent
                    }, 500, function () {
                        $('.to-open').text(titleClose)
                    });
                }
            })
        }

    }
};