export const Category = {
        init: function () {
            this.$body = $('body#category');
            this.$productGrid = this.$body.find('div.products-grid');
            // this.initImageChange();
            this.initEvents();
            this.initBackToTopCategory();
        },
        initEvents: function () {
            let that = this;
            prestashop.on('updateFacets', function () {
                that.initImageChange();
            });
        },

        initImageChange: function () {
            let that = this;
            if ($('section#products').hasClass('-facets-loading')) {
                setTimeout(function () {
                    that.initImageChange();
                }, 200);
                return;
            }

            let $children = $('div.products-grid > div.js-product-miniature-wrapper'),
                $images = $children.filter(':nth-child(3)').find('img');
            $images.each(function (i) {
                let $img = $(this);
                let src = $(this).attr('data-src');
                src = src || $(this).attr('src');
                src = src.replace('-home_default/', '-thickbox_default/');
                $img.attr('data-src', src);
                if ($img.attr('src') !== '') {
                    $img.attr('src', src);
                }

                if ($images.length === 2) {
                    if (i === 0) {
                        $img.removeClass('product-thumbnail-first').addClass('product-thumbnail-2 additional-product-thumbnail');
                    } else if (i === 1) {
                        $img.removeClass('product-thumbnail-2 additional-product-thumbnail').addClass('product-thumbnail-first has-multiple');
                    }
                } else if ($images.length > 2) {
                    if (i === 0) {
                        $img.remove();
                    } else if (i === 2) {
                        $img.removeClass('product-thumbnail-3 additional-product-thumbnail').addClass('product-thumbnail-first has-multiple');
                    }
                }
            });
        },

        initBackToTopCategory: function () {
            var width = $(window).width();
            var headerBottomClick = $('#header').outerHeight();

            $('.pagination-link').click(function () {
                $('html, body').animate({
                    scrollTop: $('#js-product-list-top').offset().top - headerBottomClick
                }, 500);
            });
            $(document).ajaxSuccess(function() {
                setTimeout(function(){
                    $('.pagination-link').click(function(){
                        $('html, body').animate({
                            scrollTop: $('#js-product-list-top').offset().top - headerBottomClick
                        }, 500);
                    });
                }, 1000);

            });



        }
};