export const Helpers = {
    initSwiper: function (selector) {
        const $container = $(selector + ' .elementor-container');
        const $wrapper = $container.find('.elementor-row');

        $container.addClass('swiper-container')
        $wrapper.addClass('swiper-wrapper')
        $wrapper.find('> div.elementor-column').addClass('swiper-slide');
        $wrapper.after($('<div>', {class: 'swiper-pagination'}));
        new Swiper($container.get(0), {
            slidesPerView: 4,
            slidesPerGroup: 2,
            spaceBetween: 30,
            horizontal: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                '@0.00': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                },
                '@0.50': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                },
                '@1.00': {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                '@1.50': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
            },
        });
    },

    overflowHidden: function () {
        const $body = $('body');
        const scrollY = $body.css('--scroll-y');
        $body.css({
            'position': 'fixed',
            'top': `-${scrollY}`
        });
    },

    overflowInitial: function () {
        const $html = $('html');
        const $body = $html.find('body');
        const scrollY = $body.css('top');
        $body.css({position: '', top: ''});
        $html.css('scroll-behavior', 'unset');
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        $html.css('scroll-behavior', '');
    }
};
